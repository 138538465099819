import React, { useContext } from 'react';
import { Layout, RecargasBtoB, PrivateRoute } from 'components';
import { Banner } from '../components/Banner';
import ProductContext from 'context/ProductContext';

export default function ModuloRecargas() {
  const { collections } = useContext(ProductContext);
  const bannerCollection = collections.find(
    collection => collection.title === 'Banners Celulares'
  );
  const mainComponent = (
    <Layout home={true}>
      <Banner id={bannerCollection.shopifyId} interno={true} />
      <RecargasBtoB />
    </Layout>
  );

  return <PrivateRoute component={mainComponent} />;
}
